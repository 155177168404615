* {
  font-family: "Poppins", sans-serif !important;

  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif !important;
  -webkit-tap-highlight-color: transparent;
  /* float: left; */
}

#root {
  width: 100%;
  position: relative;
}

@media (max-width: 800px) {
  #root {
    width: 100%;
    overflow-x: hidden;
  }
}
